import { get, merge } from 'lodash';
import { flow, mapKeys, mapValues, pickBy, startCase } from 'lodash/fp';
import { createSelector } from 'reselect';

import { CountryCodes } from 'src/common/enums';
import { isCountryCodeVN } from 'src/common/hooks/useCountryData';
import { Unleash } from 'src/global/models/Unleash';
import { State } from 'src/global/store';
import {
  getCountry,
  getCountryWithSGFallback,
} from 'src/modules/ClientConfig/Selectors';
import {
  BasedExperimentVariants,
  ExperimentApiProviders,
  ExperimentApiVariants,
  UnleashFeatureNames,
} from 'src/modules/Unleash/constants';

import { getCountryCodesFromVariantString } from './helpers';

export interface BasedVariant {
  name?: string;
  countryCodes?: CountryCodes[];
}

export const getFeatures = (state: State) => state.unleash.value;

export const getFeature = (state: State, featureName: string) =>
  getFeatures(state)[featureName];

export const createFeatureSelector = (featureName: string) => (state: State) =>
  getFeatures(state)[featureName];

export const getFeatureValue = (
  state: State,
  featureName: string,
  defaultValue?: string
) => getFeature(state, featureName)?.variant?.value ?? defaultValue;

export const getIsFeatureEnabledByCountry = (
  state: State,
  featureName: string
): boolean => {
  const countryCode = getCountryWithSGFallback(state);
  const featureFlagValues = getFeatureValue(state, featureName, '');
  const enabledCountryCodes = featureFlagValues
    ?.split(',')
    .map((s) => s.trim());

  return enabledCountryCodes?.includes(countryCode) ?? false;
};

export const getIsFeatureEnabledByVariant = (
  state: State,
  featureName: string,
  enabledVariantName: string,
  defaultValue?: string
): boolean => {
  const selectedDefaultValue = JSON.stringify({
    name: defaultValue,
    countryCodes: [],
  });

  const countryCode = getCountryWithSGFallback(state);
  const featureFlagValues = getFeatureValue(
    state,
    featureName,
    selectedDefaultValue
  );
  if (!featureFlagValues) {
    return false;
  }
  const data = JSON.parse(featureFlagValues) as BasedVariant;
  const { name, countryCodes } = data;

  const enabledCountryCodes = countryCodes?.map((s) => s.trim());
  const isValidCountryCodes =
    enabledCountryCodes?.includes(countryCode) ?? false;

  if (!isValidCountryCodes) return false;

  return isValidCountryCodes && name === enabledVariantName;
};

export const areFeaturesFetched = (state: State) =>
  !state.unleash.loading && get(state, `unleash.value`, false);

const transformExperimentKey = (prefix: string) =>
  flow<[string], string, string>(
    (key: string) => key.slice(prefix.length),
    startCase
  );

const getExperimentFeaturesValueObjectWithPrefix = (prefix: string) =>
  flow<
    [State],
    StrIndexObject<Unleash>,
    StrIndexObject<Unleash>,
    StrIndexObject<string>,
    StrIndexObject<string>
  >(
    getFeatures,
    pickBy((_, key) => key.startsWith(`${prefix}Experiment`)),
    mapValues('variant.value'),
    mapKeys(transformExperimentKey(prefix))
  );

export const getExperimentFeaturesValueObject = createSelector(
  getExperimentFeaturesValueObjectWithPrefix('all'),
  getExperimentFeaturesValueObjectWithPrefix('dst'),
  (allFeatures, dstFeatures) => {
    return merge(allFeatures, dstFeatures);
  }
);

export const getIsEnableMobileDownloadModal = (state: State) =>
  Boolean(
    createFeatureSelector(UnleashFeatureNames.dstEnableMobileDownloadModal)(
      state
    )
  );

export const getIsTWJobFilterEnabled = (state: State) => {
  return Boolean(getFeature(state, UnleashFeatureNames.dstTWJobFilter));
};

/*
 * Temporarily disable default country query for TW
 * https://glints.slack.com/archives/C94GRV86M/p1602672139064800
 */
export const getIsDefaultCountryQueryEnabled = (state: State) => {
  const isTW = getCountry(state) === CountryCodes.TW;
  const isTWJobFilterEnabled = getIsTWJobFilterEnabled(state);

  return !isTW || isTWJobFilterEnabled;
};

export const getIsFollowingFeatureEnabled = (state: State) => {
  return Boolean(
    getFeature(state, UnleashFeatureNames.dstFollowerNotificationPreference)
  );
};

export const getExplorePageFilterOrderVariant = (state: State): string =>
  getFeatureValue(
    state,
    UnleashFeatureNames.dstExperimentExplorePageFilterOrder,
    'original'
  ) as string;

export const getIsOdysseyEnabled = (state: State) => {
  const countryCode = getCountryWithSGFallback(state);
  const disabledCountryCodes = getFeatureValue(
    state,
    UnleashFeatureNames.dstOdyssey,
    ''
  )
    .split(',')
    .map((s) => s.trim());
  return disabledCountryCodes.includes(countryCode)
    ? false
    : Boolean(getFeature(state, UnleashFeatureNames.dstOdyssey));
};

export const getIsSupersonicBannerEnabled = (state: State) =>
  Boolean(getFeature(state, UnleashFeatureNames.dstIsSupersonicBannerEnabled));

export const getIsJobInterviewProcessEnabled = (state: State) =>
  Boolean(getFeature(state, UnleashFeatureNames.dstInterviewProcess));

export const getIsApplicationStatusTrackingEnabled = (state: State) =>
  Boolean(getFeature(state, UnleashFeatureNames.dstApplicationStatusTracking));

export const getImproveJobSearchFilterEnabled = (state: State) =>
  Boolean(getFeature(state, UnleashFeatureNames.dstImproveJobSearchFilter));

export const getIsCreateStreamChannelEnabled = (state: State) =>
  Boolean(getFeature(state, UnleashFeatureNames.dstCreateStreamChannel));

/**
 * feature flag for whether to apply showing for you page CTA when search job is empty
 * this function will return list of country codes that this feature is applied to
 *
 * @param state
 */
export const getEmptyJobSearchRedirectToFYPCountryCodes = (
  state: State
): CountryCodes[] => {
  return getCountryCodesFromVariantString(
    getFeatureValue(
      state,
      UnleashFeatureNames.dstEmptyJobSearchRedirectToFYPCTA,
      ''
    )
  );
};

/**
 * feature flag for whether to apply showing education level requirement on
 * job cards and job details.
 * this function will return list of country codes that this feature is applied to
 *
 * @param state
 */
export const getViewEducationLevelEnabled = (state: State): CountryCodes[] => {
  return getCountryCodesFromVariantString(
    getFeatureValue(state, UnleashFeatureNames.dstViewEducationLevelEnabled, '')
  );
};

/**
 * feature flag for whether to show chat application status on
 * my applicaitons and application details.
 * this function will return list of country codes that this feature is applied to
 *
 * @param state
 */
export const getChatApplicationStatusEnabledCountries = (
  state: State
): CountryCodes[] => {
  return getCountryCodesFromVariantString(
    getFeatureValue(
      state,
      UnleashFeatureNames.dstChatApplicationStatusEnabled,
      ''
    )
  );
};

/**
 * feature flag for whether to show nudge to chat with hiring manager after
 * applying to a job on my job details page. Both for mobile web and desktop web.
 * this function will return list of country codes that this feature is applied to
 *
 * @param state
 */
export const getNudgeChatWithHiringManagerEnabledCountries = (
  state: State
): CountryCodes[] => {
  return getCountryCodesFromVariantString(
    getFeatureValue(
      state,
      UnleashFeatureNames.dstNudgeChatWithHiringManager,
      ''
    )
  );
};

export const getIsResumeParsingEnabled = (state: State) =>
  Boolean(getFeature(state, UnleashFeatureNames.dstResumeParsingEnabled));

export const checkIsJobSearchImproveEnabled = (state: State) =>
  Boolean(
    getFeature(state, UnleashFeatureNames.allExperimentsExploreAndForYou)
  );

export const getJobSearchImproveVariant = (
  state: State,
  defaultValue: string
) =>
  getFeatureValue(
    state,
    UnleashFeatureNames.allExperimentsExploreAndForYou,
    defaultValue
  );

export const getIsSearchJobsFilterV2Enabled = (state: State) =>
  Boolean(getFeature(state, UnleashFeatureNames.dstSearchJobFilterV2));

export const getExperimentApplicationWarningVariant = (
  state: State,
  defaultValue: string
) =>
  getFeatureValue(
    state,
    UnleashFeatureNames.dstExperimentApplicationWarning,
    defaultValue
  );

export const getHideJobCategoryFilter = (state: State) =>
  Boolean(getFeature(state, UnleashFeatureNames.dstHideJobCategoryFilter));

export const getCompanyNormalisedLocationEnabled = (state: State) =>
  Boolean(
    getFeature(state, UnleashFeatureNames.dstCompanyNormalisedLocationEnabled)
  );

export const getIsWhatsappSupportEnabled = (state: State) => {
  return Boolean(getFeature(state, UnleashFeatureNames.dstWhatsappSupport));
};

export const getIsShowLoginPopupEnabled = (state: State) => {
  return getIsFeatureEnabledByCountry(
    state,
    UnleashFeatureNames.dstShowLoginPopup
  );
};

export const getIsShowApplicationFormsEnabled = (state: State) => {
  return getIsFeatureEnabledByCountry(
    state,
    UnleashFeatureNames.dstShowApplicationForm
  );
};

export const getIsHideResumeSectionInApplicationPageEnabled = (
  state: State
) => {
  return Boolean(
    getFeature(state, UnleashFeatureNames.dstHideResumeSectionInApplicationPage)
  );
};

export const getIsSkillsV2Hidden = (state: State) => {
  return Boolean(getFeature(state, UnleashFeatureNames.dstHideSkillsV2));
};

export const getIsGetJobTitleSuggestionV2Enabled = (state: State) => {
  return Boolean(
    getFeature(state, UnleashFeatureNames.dstGetJobTitleSuggestionV2)
  );
};

export const getV2ApiProvider = (state: State) =>
  getFeatureValue(
    state,
    UnleashFeatureNames.dstExperimentApiProvider,
    ExperimentApiProviders.AWS
  );

export const getIsApplyJobV2Enabled = (state: State) => {
  return Boolean(getFeature(state, UnleashFeatureNames.dstApplyJobV2));
};

export const getIsShowMoreJobRecommendataionFiltersEnabled = (state: State) => {
  return Boolean(
    getFeature(state, UnleashFeatureNames.dstShowMoreJobRecommendationFilters)
  );
};

export const getIsApplyOnAppEnabled = (state: State) => {
  return Boolean(
    getFeature(state, UnleashFeatureNames.dstShowApplyOnAppButton)
  );
};

export const getIsForYouV3Enabled = (state: State) => {
  return getIsFeatureEnabledByVariant(
    state,
    UnleashFeatureNames.dstExperimentFYPV3,
    BasedExperimentVariants.VARIANT_B,
    BasedExperimentVariants.VARIANT_A
  );
};

export const getRecommendationsExperimentVariant = (state: State) => {
  return getIsForYouV3Enabled(state)
    ? BasedExperimentVariants.VARIANT_B
    : BasedExperimentVariants.VARIANT_A;
};

export const getIsSearchJobsV3Enabled = (state: State) => {
  return getIsFeatureEnabledByVariant(
    state,
    UnleashFeatureNames.dstExperimentSearchJobV3,
    ExperimentApiVariants.V3,
    ExperimentApiVariants.V2
  );
};

export const getSearchJobsExperimentVariant = (state: State) => {
  return getIsSearchJobsV3Enabled(state)
    ? ExperimentApiVariants.V3
    : ExperimentApiVariants.V2;
};

export const getIsMigrationToApiV2Enabled = (state: State) => {
  return Boolean(getFeature(state, UnleashFeatureNames.dstMigrationToApiV2));
};

export const getIsJobDetailRevisingEnabled = (state: State) => {
  const countryCode = getCountryWithSGFallback(state);
  const isCountryVN = isCountryCodeVN(countryCode);
  if (isCountryVN) return true;

  return getIsFeatureEnabledByVariant(
    state,
    UnleashFeatureNames.dstExperimentJobDetailRevising,
    BasedExperimentVariants.VARIANT_B,
    BasedExperimentVariants.VARIANT_A
  );
};

export const getIsAirbridgeLinksEnabled = (state: State) => {
  return getIsFeatureEnabledByVariant(
    state,
    UnleashFeatureNames.dstAirBridgeLinksEnabled,
    BasedExperimentVariants.VARIANT_B,
    BasedExperimentVariants.VARIANT_A
  );
};
