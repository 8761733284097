import { CountryCodes } from 'src/common/enums';

import { BasedExperimentVariants, ExperimentVariant } from './constants';

export const getCountryCodesFromVariantString = (featureValue: string) =>
  featureValue.split(',').map((s) => s.trim() as CountryCodes);

export const variantToTrackingCode: Record<ExperimentVariant, string> = {
  VARIANT_A: 'A',
  VARIANT_B: 'B',
};

export const getTrackingVariantCode = (isEnabled: boolean): string => {
  const variant: ExperimentVariant = isEnabled
    ? BasedExperimentVariants.VARIANT_B
    : BasedExperimentVariants.VARIANT_A;
  return variantToTrackingCode[variant];
};
