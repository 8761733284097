import { debounce } from 'lodash';
import { UrlObject } from 'url';

export const enhanceHrefWithUtmParams = (
  href: string | UrlObject,
  utmParams: Record<string, string>
) => {
  if (typeof href === 'string') {
    const [pathname, existingQuery] = href.split('?');
    const query = { ...utmParams };

    if (existingQuery) {
      const searchParams = new URLSearchParams(existingQuery);
      searchParams.forEach((value, key) => {
        if (value !== '') {
          query[key] = value;
        }
      });
    }

    return {
      pathname,
      query: Object.fromEntries(
        Object.entries(query).filter(([_, value]) => value !== '')
      ),
    };
  }

  const query = typeof href.query === 'object' ? href.query : {};

  const cleanQuery = Object.fromEntries(
    Object.entries({ ...query, ...utmParams }).filter(
      ([_, value]) => value !== '' && value !== false
    )
  );

  return {
    ...href,
    query: cleanQuery,
  };
};

/**
 * This help set a limited debounce time to prevent this issue on Safari:
 * SecurityError: Attempt to use history.replaceState() more than 100 times per 10 seconds`
 * and improve loading performance.
 *
 * @reference https://stackoverflow.com/questions/67038317/unhandled-rejection-securityerror-attempt-to-use-history-pushstate-more-tha
 */

export const debouncedUpdateUrl = debounce((url: string) => {
  window.history.replaceState(
    { ...window.history.state, as: url, url: url },
    '',
    url
  );
}, 300);
